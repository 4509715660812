import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-driver',
  templateUrl: './privacy-driver.component.html',
  styleUrls: ['./privacy-driver.component.css']
})
export class PrivacyDriverComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
