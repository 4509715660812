import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';

@Injectable()
export class PayoutsService {
  private activeFilter = {};
  private activeLink = "";
  constructor(private api: ApiService) { }
  listStates(){
    return this.api.genericGet(environment.payouts.states);
  }
  list(limit: number, offset: number): Observable<any>{
    let url = this.applyUrlFilters(environment.payouts.base);
    url += "limit="+limit+"&";
    url += "offset="+offset+"&";
    return this.api.genericGet(url);
  }
  listCustomers(driverId: string, limit: number, offset: number): Observable<any>{
    let url = this.applyUrlFilters(`${environment.payouts.customers}/${driverId}`);
    url += "limit="+limit+"&";
    url += "offset="+offset+"&";
    return this.api.genericGet(url);
  }
  listCompanies(limit: number, offset: number, status: string = "preordered"): Observable<any>{
    let url = this.applyUrlFilters(environment.payouts.pending);
    url += "limit="+limit+"&";
    url += "offset="+offset+"&";
    return this.api.genericGet(url);
  }
  listPendingDriver(driverId: string, limit: number, offset: number, status: string = "preordered"): Observable<any>{
    let url = this.applyUrlFilters(`${environment.payouts.drivers}/${driverId}`);
    url += "limit="+limit+"&";
    url += "offset="+offset+"&";
    return this.api.genericGet(url);
  }
  updatePayout(id: string){
    return this.api.genericPut(`${environment.payouts.base}/${id}`, {});

  }
  driverPayouts(){

  }
  companyPayouts(){

  }
  setFilter(name: any, value: any){
    this.activeFilter[name] = value;
  }
  removeFilters(){
    this.activeFilter = {};
  }
  applyUrlFilters(url: string){
    let auxUrl = url + "?";
    for (let filter of Object.keys(this.activeFilter)) {
      auxUrl += filter + "=" + this.activeFilter[filter] + "&";
    }
    return auxUrl;
  }
}
