const END_POINT = "https://app.kamgus.com/api/index.php/kamgus";
const PAY = "https://app.kamgus.com/api/index.php";
const END_POINT2 = "http://www.api.kamgus.com/v2/general";
const END_POINT3 = "https://www.api.kamgus.com/usuario-v2/index.php/kamgus";
const APIKAMGUS = "http://apikamgusv2.kamgus.com/api";
const BASE_URL_IMG = "http://apikamgusv2.kamgus.com/documentos";
const BASE_URL_IMG_USER = "http://apikamgusv2.kamgus.com/usuarios";
const PROFILE_PATH = "http://apikamgusv2.kamgus.com/profile/images";
export const environment = {
  //url base apikamgus
  apikamgus_base_url: APIKAMGUS + "/v2",
  base_url_path: BASE_URL_IMG,
  base_url_path_user: BASE_URL_IMG_USER,
  base_url_profile_image: PROFILE_PATH,

  //apis anteriores
  // apikamgus_base_url: APIKAMGUS + "/v2",
  crearUsuario: END_POINT2 + "/driverRegister", //No usado
  crearUser: APIKAMGUS+'/v2'+'/users/create', //M_S // END_POINT2 + "/userRegister"s
  production: false,
  //generic: END_POINT,
  genericDos: END_POINT2,//
  genericThird: END_POINT3,
  login: END_POINT + "/login",
  licences: APIKAMGUS+'/v2' + "/licenses",
  licencesModules: END_POINT + "/licenceModules",
  validateLicence: END_POINT + "/validateLicence",
  activateFreeLicence: END_POINT + "/activateFreeLicence",
  getLicenceByUser: END_POINT + "/getLicenceByUser",
  register: END_POINT + "/register",
  payCard: PAY + "/Transactions/preAuth",

  //redirección para descargar las apps
  web: "https://www.kamgus.com",
  google: "https://play.google.com/store/apps/details?id=com.kamgusu.pa",
  ios: "https://apps.apple.com/us/app/id1495896386",
  googleDriver: "https://play.google.com/store/apps/details?id=com.kamgus.pa",
  iosDriver: "https://apps.apple.com/us/app/id1495896386",

  //pkStripe: 'pk_live_51K6crsEumLY5hcKQ662RmgRGeUJZwNTskwGAFNdo7dUisOFMI8NtlQayWetMlYgryfrfmtaIClUfrf6xQTqLI7HV00b4ugc4fY',
  pkStripe: 'pk_test_51K6crsEumLY5hcKQ8p1xZ1eeBirue6dKTk8e2mUj7G78nWgeVPcCFORzRyEdpTWyr1xjHjz8PWNxC4cTb3uxFh1I00ajY5UcvI',
  users: {
    card: {
      setup_intent: ""
    },
    payouts: {
      deposit: APIKAMGUS+'/v2'+'/admin/users/deposit',
      takeout: APIKAMGUS+'/v2'+'/admin/users/take_out'
    }
  }, 
  support:{
    base: "/support",
    categories: "/support/categories",
  },
  roles:{
    base: "/roles",
    update: "/roles/role",
    permissions: "/roles/permissions",
    permission: "/roles/permission",
  },
  licenses: {
    base: "/admin/licenses",
  },
  settings: {
    base: "/admin/settings",
  },
  service: {
    base: "/admin/services",
    update: "/admin/services/transport_type",
  },
  payouts: {
    base: "/admin/payouts",
    states: "/admin/payouts/states",
    customers: "/admin/payouts/customers",
    pending: "/admin/payouts/pending",
    drivers: "/admin/payouts/pending",
  }
};
